.chatmodal-starting-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
    padding: 1rem;
    grid-gap: 0.6vw;
    gap: 0.6vw;
}

.chatmodal-option-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 8px;
    gap: 8px;
    width: 200px;
    padding: 16px;
    background-color: #fff;
    border: 2px solid #ddd;
    border-radius: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
    
}

.chatmodal-option-button:hover {
    background-color: #f0f0f0;
}

.chatmodal-option-icon {
    width: 48px;
    height: 48px;
    filter: brightness(0) invert(0);
    transition: filter 0.3s ease;
}

.chatmodal-option-button:hover .chatmodal-option-icon {
    filter: brightness(0.2); 
}

.chatmodal-option-button span {
    font-size: 16px;
    text-align: center;
    color: #333;
}
.chatmodal-main-logo{
    width: 10%;
    height: 10%;
    margin: auto;
}

.chatmodal-nochat-selected-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.chatmodal-welcome-title {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    color: black;
    margin-top: 0.5rem;
}
.chat-messages {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  font-family: 'Inter', Arial, sans-serif;
  line-height: 1.5;
}

.welcome-container {
  flex: 1 1;
}

.message-list {
  flex: 5 1;
  overflow-y: auto;
  padding: 15px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.message-list::-webkit-scrollbar {
  width: 6px;
}

.message-list::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.message {
  margin: 5px 0;
  padding: 10px;
  border-radius: 8px;
}
 
.tip-buttons {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  margin-bottom: 8px;
}

.message-sender {
  font-weight: bold;
  margin-bottom: 2px;
  font-size: 0.85rem;
  color: #555;
}

.bot-wrapper {
  align-items: flex-start;
}

.message-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}

.user-wrapper {
  align-items: flex-end;
}

.message-container {
  width: -moz-fit-content;
  width: fit-content;
  max-width: 60%;
}

.user-message {
  align-self: flex-end;
  background-color: #d0e0ff;
  border-radius: 8px 8px 0 8px;
}

.bot-message {
  align-self: flex-start;
  background-color: #f0f0f0;
  border-radius: 8px 8px 8px 0;
}

.code-block {
  position: relative;
  max-width: 100%;
  overflow: auto;
  background: rgb(47, 47, 47);
  border-radius: 8px;
  overflow-x: auto;
}

.code-block pre {
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
  max-width: 100% !important;
  overflow-x: auto;
  overflow-wrap: break-word;
  max-width: 100%;
}


.code-block span {
  white-space: pre-wrap !important;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  max-width: 100%;
}


.copy-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgb(47, 47, 47);
  border: none;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
}


.chat-messages .copy-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 12px;
}

.copy-button:hover {
  background-color: #45a049;
}

.scroll-to-bottom-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10;
}

.message-input {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  padding: 5px;
  flex: 1 1;
}
.chat-message-input {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  padding: 15px;
}
.message-input-field {
  width: 100%;
}
.chat-image-button {
border: none;
cursor: pointer;
font-size: 20px;
border-radius: 30px;
}
.chat-image-icon {
width: 45px;
height: 45px;
filter: brightness(0) invert(0);
transition: filter 0.3s ease;
}

.message-chatbot-logo {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  vertical-align: middle;
}

.chat-dislike-container {
  margin-top: 8px;
  text-align: right;
}

.chat-dislike-button {
  font-size: 0.75rem;
  padding: 0;
  min-width: 32px;
  height: 32px;
  background-color: transparent;
}

.chat-dislike-button img {
  width: 20px;
  height: 20px;
  filter: brightness(0);
}

.chat-dislike-button:hover {
  background-color: #f5f5f5;
}

.uploaded-image-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  max-width: 100px;
}

.image-preview {
  width: 80px;
  height: auto;
  border-radius: 5px;
  margin-bottom: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.image-name {
  font-size: 12px;
  text-align: center;
  color: #555;
  word-wrap: break-word;
  font-weight: bold;
}

.remove-image-button {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #ff5555;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.add-photo-button {
  margin-bottom: 10px;
}

.chat-add-image-icon {
  width: 50px;
  height: 50px;
  vertical-align: middle;
  filter: brightness(0) invert(0);
}

.error-message {
  color: red;
  font-size: 0.9rem;
  margin: 5px 0;
}

.message-image img {
  max-width: 200px;
  max-height: 200px;
  width: auto;
  height: auto;
  border-radius: 8px;
  object-fit: contain;
}

.message-content pre {
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 100%;
}

.message-content .inline-code {
  white-space: pre-wrap;
  word-break: break-word;
  max-width: 100%;
  overflow-wrap: break-word;
  display: inline-block;
}

.bot-tips {
  max-width: 100%;
}

.message-content {
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.chatmodal-sidebar {
  background-color: #e3e3e3;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.sidebar-button {
  border-radius: 15px;
  background-color: transparent;
  border: none;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  grid-gap: 8px;
  gap: 8px;
  font-family: 'Inter', Arial, sans-serif;
  transition: background 0.3s ease;
}

.sidebar-button:hover {
  background-color: #c6c5c5;
}

.sidebar-chats-container {
  flex: 10 1;
  overflow-y: auto;
  max-height: 60vh;
}

.sidebar-chats {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 1rem;
  overflow-y: auto;
}

.chat-item-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 8px 0;
}

.sidebar-archived-chats {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-top: 3px solid white;
  flex: 1 1;
}

.icon-right {
  width: 24px;
  height: 24px;
  filter: brightness(0) invert(0);
  transition: filter 0.3s ease;
}

.icon-right:hover {
  filter: brightness(0.2);
}

.menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  border-radius: 4px;
  min-width: 150px;
}

.menu ul {
  list-style: none;
  padding: 10px;
  margin: 0;
}

.chat-item-text {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar-create-new-chat {
  margin-bottom: 1vh;
  margin-top: 1vh;
}
.chat-modal {
    display: flex;
    flex-direction: column;
    width: 80vw;
    height: 80vh; 
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.chat-body {
    display: flex;
    flex: 1 1;
    overflow: hidden;
}

.message-list {
    flex: 3 1;
    overflow-y: auto;
    margin-bottom: 10px;
}

.user-message {
    align-self: flex-end;
    background-color: #ff4729;
    color: #fff;
    padding: 8px 12px;
    border-radius: 10px;
    margin: 5px 0;
    max-width: 80%;
}

.bot-message {
    align-self: flex-start;
    background-color: #e9ecef;
    color: #333;
    padding: 8px 12px;
    border-radius: 10px;
    margin: 5px 0;
    max-width: 80%;
}

.message-input {
    display: flex;
    align-items: center;
    width: 100%; 
    grid-gap: 100px; 
    gap: 100px;
}

.message-input .MuiOutlinedInput-root {
    flex: 5 1;
}

.send-button {
    flex: 1 1; 
    padding: 10px 15px;
    background-color: #ff4729;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    flex-shrink: 0;
}

.send-button:hover {
    background-color: #e04429;
}

.chat-messages-and-input-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 3.5 1;
}

.title-logo {
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.chat-header {
    background-color: #ff4729;
    color: #fff;
    padding: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-header-left {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.chat-header h2 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}

.chat-header button {
    background: transparent;
    border: none;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
}

.app-chatbot-container {
  margin: 40px 0;
  display: flex;
  justify-content: center;
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 1299;
  box-shadow: 5px 5px 13px rgba(91, 81, 81, 0.4);
  font-family: 'Roboto', sans-serif;
  border-radius: 5px;
}

.react-chatbot-kit-chat-container {
  width: 340px;
}

.app-chatbot-button {
  width: 60px;
  height: 60px;
  border-radius: 25px;
  background-color: transparent;
  border: none;
  position: fixed;
  bottom: 15px;
  z-index: 1299;
  right: 30px;
}

.app-chatbot-button-icon {
  width: 60px;
  height: 60px;
  fill: #fff;
}

.react-chatbot-kit-chat-bot-message {
  background-color: #878fac;
  width: 100%;
  margin-left: 0;
}

.react-chatbot-kit-chat-bot-avatar {
  display: none;
}

.react-chatbot-kit-user-chat-message {
  inline-size: auto;
  overflow-wrap: break-word;
  display: flex;
  justify-content: flex-end;
}

.react-chatbot-kit-user-avatar {
  display: none;
}

.chatmodal-messages-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: scroll;
    min-height: 50vh;
    max-height: 50vh;
    flex:6 1;
    padding: 15px;
}

.chatmodal-message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 1rem;
}

.chatmodal-message .chatmodal-message-role {
    text-align: left;
    color: red;
    font-weight: bold;
}

.chatmodal-message .chatmodal-response {
    border-radius: 10px;
    background-color: #e5e5ea;
    min-width: 80%;
    max-width: 80%;
    overflow-x: scroll;
    padding: 0.5rem 1rem;
}

.chatmodal-message-loading {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 1.5rem;
}

.chatmodal-llm-select-buttons {
    padding: 0rem;
    margin-top: 1rem;
    margin-bottom: 0;
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.chatmodal-messages-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: scroll;
  min-height: 50vh;
  max-height: 50vh;
}

.chatmodal-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.chatmodal-message .chatmodal-message-role {
  text-align: left;
  color: red;
  font-weight: bold;
}

.chatmodal-message .chatmodal-response {
  border-radius: 10px;
  background-color: #e5e5ea;
  min-width: 80%;
  max-width: 80%;
  overflow-x: scroll;
  padding: 0.5rem 1rem;
}

.chatmodal-message-loading {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 1.5rem;
}
.chatmodal-container {
  overflow: clip;
}

.chatmodal-conversation-window {
  display: flex;
  flex-direction: column;
  flex: 6 1;
  transition: margin-left 0.3s ease;
}

.chatmodal-collapse-button {
  position: absolute;
  padding: 0px;
  top: 8.5vh;
  left: 12vw;
  background-color: transparent;
  cursor: pointer;
  border-radius: 10px;
  border: none;
  transition: background 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
}

.chatmodal-collapse-button:hover {
  background-color: #c6c5c5;
}

.chatmodal-chat-content-container {
  display: flex;
  flex-direction: row;
}

.chatmodal-messages-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: scroll;
  min-height: 50vh;
  max-height: 50vh;
}

.chatmodal-title {
  display: flex;
  flex-direction: row;
  font-size: 1.1rem;
  justify-content: space-between;
  padding: 0.5rem 0rem 0.5rem 1.5rem;
  background-color: #ff4729;
  color: white;
  font-weight: bold;
  align-items: center;
}

.MuiDialogTitle-root-352 {
  padding: 0px;
}

.chatmodal-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: small;
  margin-bottom: 1rem;
}

.chatmodal-message .chatmodal-message-role {
  text-align: left;
  color: red;
  font-weight: bold;
}

.chatmodal-message .chatmodal-response {
  border-radius: 10px;
  background-color: #e5e5ea;
  padding: 0.5rem 1rem;
}

.chatmodal-message-input {
  display: flex;
  flex-direction: col;
  align-items: center;
  justify-content: between;
  margin: 0.5rem 1.5rem;
  border-radius: 15px;
}

.chatmodal-message-loading {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.chatmodal-message-loading-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000;
  margin-top: 2rem;
  animation: dotElastic 1s infinite ease-in-out;
}

.chatmodal-engine-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 0rem 0rem;
}

.chatmodal-engine-feedback-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.chatmodal-tab-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 0rem 0rem;
}

.chatmodal-tabs {
  text-transform: none;
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
  overflow: visible;
}

.chatmodal-tabs-tab {
  font-size: 0.8rem;
  font-weight: bold;
  width: 100%;
  height: 100%;
  overflow: visible;
}
.chatmodal-engine-feedback-dialog .chatmodal-engine-feedback-dialog-title {
  font-size: medium;
  font-weight: bold;
}

.chatmodal-engine-feedback-dialog .chatmodal-engine-feedback-dialog-buttons {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
}

.chatmodal-engine-feedback-dialog .chatmodal-engine-feedback-dialog-buttons button {
  width: 15%;
  height: 1.5rem;
}

.chat-logo {
  width: 50px;
  height: 50px;
  padding: 0px;
}

.chatmodal-send-button {
  border-radius: 15px;
}


