
.chatmodal-messages-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: scroll;
    min-height: 50vh;
    max-height: 50vh;
    flex:6;
    padding: 15px;
}

.chatmodal-message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 1rem;
}

.chatmodal-message .chatmodal-message-role {
    text-align: left;
    color: red;
    font-weight: bold;
}

.chatmodal-message .chatmodal-response {
    border-radius: 10px;
    background-color: #e5e5ea;
    min-width: 80%;
    max-width: 80%;
    overflow-x: scroll;
    padding: 0.5rem 1rem;
}

.chatmodal-message-loading {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 1.5rem;
}

.chatmodal-llm-select-buttons {
    padding: 0rem;
    margin-top: 1rem;
    margin-bottom: 0;
    display: flex;
    gap: 0.5rem;
}