.chat-modal {
    display: flex;
    flex-direction: column;
    width: 80vw;
    height: 80vh; 
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.chat-body {
    display: flex;
    flex: 1;
    overflow: hidden;
}

.message-list {
    flex: 3;
    overflow-y: auto;
    margin-bottom: 10px;
}

.user-message {
    align-self: flex-end;
    background-color: #ff4729;
    color: #fff;
    padding: 8px 12px;
    border-radius: 10px;
    margin: 5px 0;
    max-width: 80%;
}

.bot-message {
    align-self: flex-start;
    background-color: #e9ecef;
    color: #333;
    padding: 8px 12px;
    border-radius: 10px;
    margin: 5px 0;
    max-width: 80%;
}

.message-input {
    display: flex;
    align-items: center;
    width: 100%; 
    gap: 100px;
}

.message-input .MuiOutlinedInput-root {
    flex: 5;
}

.send-button {
    flex: 1; 
    padding: 10px 15px;
    background-color: #ff4729;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    flex-shrink: 0;
}

.send-button:hover {
    background-color: #e04429;
}

.chat-messages-and-input-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 3.5;
}

.title-logo {
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.chat-header {
    background-color: #ff4729;
    color: #fff;
    padding: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-header-left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.chat-header h2 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}

.chat-header button {
    background: transparent;
    border: none;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
}
