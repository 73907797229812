.chatmodal-container {
  overflow: clip;
}

.chatmodal-conversation-window {
  display: flex;
  flex-direction: column;
  flex: 6;
  transition: margin-left 0.3s ease;
}

.chatmodal-collapse-button {
  position: absolute;
  padding: 0px;
  top: 8.5vh;
  left: 12vw;
  background-color: transparent;
  cursor: pointer;
  border-radius: 10px;
  border: none;
  transition: background 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
}

.chatmodal-collapse-button:hover {
  background-color: #c6c5c5;
}

.chatmodal-chat-content-container {
  display: flex;
  flex-direction: row;
}

.chatmodal-messages-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: scroll;
  min-height: 50vh;
  max-height: 50vh;
}

.chatmodal-title {
  display: flex;
  flex-direction: row;
  font-size: 1.1rem;
  justify-content: space-between;
  padding: 0.5rem 0rem 0.5rem 1.5rem;
  background-color: #ff4729;
  color: white;
  font-weight: bold;
  align-items: center;
}

.MuiDialogTitle-root-352 {
  padding: 0px;
}

.chatmodal-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: small;
  margin-bottom: 1rem;
}

.chatmodal-message .chatmodal-message-role {
  text-align: left;
  color: red;
  font-weight: bold;
}

.chatmodal-message .chatmodal-response {
  border-radius: 10px;
  background-color: #e5e5ea;
  padding: 0.5rem 1rem;
}

.chatmodal-message-input {
  display: flex;
  flex-direction: col;
  align-items: center;
  justify-content: between;
  margin: 0.5rem 1.5rem;
  border-radius: 15px;
}

.chatmodal-message-loading {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.chatmodal-message-loading-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000;
  margin-top: 2rem;
  animation: dotElastic 1s infinite ease-in-out;
}

.chatmodal-engine-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 0rem 0rem;
}

.chatmodal-engine-feedback-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.chatmodal-tab-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 0rem 0rem;
}

.chatmodal-tabs {
  text-transform: none;
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
  overflow: visible;
}

.chatmodal-tabs-tab {
  font-size: 0.8rem;
  font-weight: bold;
  width: 100%;
  height: 100%;
  overflow: visible;
}
.chatmodal-engine-feedback-dialog .chatmodal-engine-feedback-dialog-title {
  font-size: medium;
  font-weight: bold;
}

.chatmodal-engine-feedback-dialog .chatmodal-engine-feedback-dialog-buttons {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.chatmodal-engine-feedback-dialog .chatmodal-engine-feedback-dialog-buttons button {
  width: 15%;
  height: 1.5rem;
}

.chat-logo {
  width: 50px;
  height: 50px;
  padding: 0px;
}

.chatmodal-send-button {
  border-radius: 15px;
}

