.chatmodal-starting-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
    padding: 1rem;
    gap: 0.6vw;
}

.chatmodal-option-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 200px;
    padding: 16px;
    background-color: #fff;
    border: 2px solid #ddd;
    border-radius: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
    
}

.chatmodal-option-button:hover {
    background-color: #f0f0f0;
}

.chatmodal-option-icon {
    width: 48px;
    height: 48px;
    filter: brightness(0) invert(0);
    transition: filter 0.3s ease;
}

.chatmodal-option-button:hover .chatmodal-option-icon {
    filter: brightness(0.2); 
}

.chatmodal-option-button span {
    font-size: 16px;
    text-align: center;
    color: #333;
}
.chatmodal-main-logo{
    width: 10%;
    height: 10%;
    margin: auto;
}

.chatmodal-nochat-selected-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.chatmodal-welcome-title {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    color: black;
    margin-top: 0.5rem;
}