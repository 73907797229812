.chatmodal-sidebar {
  background-color: #e3e3e3;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.sidebar-button {
  border-radius: 15px;
  background-color: transparent;
  border: none;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  font-family: 'Inter', Arial, sans-serif;
  transition: background 0.3s ease;
}

.sidebar-button:hover {
  background-color: #c6c5c5;
}

.sidebar-chats-container {
  flex: 10;
  overflow-y: auto;
  max-height: 60vh;
}

.sidebar-chats {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 1rem;
  overflow-y: auto;
}

.chat-item-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 8px 0;
}

.sidebar-archived-chats {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-top: 3px solid white;
  flex: 1;
}

.icon-right {
  width: 24px;
  height: 24px;
  filter: brightness(0) invert(0);
  transition: filter 0.3s ease;
}

.icon-right:hover {
  filter: brightness(0.2);
}

.menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  border-radius: 4px;
  min-width: 150px;
}

.menu ul {
  list-style: none;
  padding: 10px;
  margin: 0;
}

.chat-item-text {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar-create-new-chat {
  margin-bottom: 1vh;
  margin-top: 1vh;
}