.chat-messages {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  font-family: 'Inter', Arial, sans-serif;
  line-height: 1.5;
}

.welcome-container {
  flex: 1;
}

.message-list {
  flex: 5;
  overflow-y: auto;
  padding: 15px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.message-list::-webkit-scrollbar {
  width: 6px;
}

.message-list::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.message {
  margin: 5px 0;
  padding: 10px;
  border-radius: 8px;
}
 
.tip-buttons {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
}

.message-sender {
  font-weight: bold;
  margin-bottom: 2px;
  font-size: 0.85rem;
  color: #555;
}

.bot-wrapper {
  align-items: flex-start;
}

.message-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}

.user-wrapper {
  align-items: flex-end;
}

.message-container {
  width: fit-content;
  max-width: 60%;
}

.user-message {
  align-self: flex-end;
  background-color: #d0e0ff;
  border-radius: 8px 8px 0 8px;
}

.bot-message {
  align-self: flex-start;
  background-color: #f0f0f0;
  border-radius: 8px 8px 8px 0;
}

.code-block {
  position: relative;
  max-width: 100%;
  overflow: auto;
  background: rgb(47, 47, 47);
  border-radius: 8px;
  overflow-x: auto;
}

.code-block pre {
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
  max-width: 100% !important;
  overflow-x: auto;
  overflow-wrap: break-word;
  max-width: 100%;
}


.code-block span {
  white-space: pre-wrap !important;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  max-width: 100%;
}


.copy-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgb(47, 47, 47);
  border: none;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
}


.chat-messages .copy-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 12px;
}

.copy-button:hover {
  background-color: #45a049;
}

.scroll-to-bottom-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10;
}

.message-input {
  display: flex;
  gap: 5px;
  padding: 5px;
  flex: 1;
}
.chat-message-input {
  display: flex;
  gap: 15px;
  padding: 15px;
}
.message-input-field {
  width: 100%;
}
.chat-image-button {
border: none;
cursor: pointer;
font-size: 20px;
border-radius: 30px;
}
.chat-image-icon {
width: 45px;
height: 45px;
filter: brightness(0) invert(0);
transition: filter 0.3s ease;
}

.message-chatbot-logo {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  vertical-align: middle;
}

.chat-dislike-container {
  margin-top: 8px;
  text-align: right;
}

.chat-dislike-button {
  font-size: 0.75rem;
  padding: 0;
  min-width: 32px;
  height: 32px;
  background-color: transparent;
}

.chat-dislike-button img {
  width: 20px;
  height: 20px;
  filter: brightness(0);
}

.chat-dislike-button:hover {
  background-color: #f5f5f5;
}

.uploaded-image-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  max-width: 100px;
}

.image-preview {
  width: 80px;
  height: auto;
  border-radius: 5px;
  margin-bottom: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.image-name {
  font-size: 12px;
  text-align: center;
  color: #555;
  word-wrap: break-word;
  font-weight: bold;
}

.remove-image-button {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #ff5555;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.add-photo-button {
  margin-bottom: 10px;
}

.chat-add-image-icon {
  width: 50px;
  height: 50px;
  vertical-align: middle;
  filter: brightness(0) invert(0);
}

.error-message {
  color: red;
  font-size: 0.9rem;
  margin: 5px 0;
}

.message-image img {
  max-width: 200px;
  max-height: 200px;
  width: auto;
  height: auto;
  border-radius: 8px;
  object-fit: contain;
}

.message-content pre {
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 100%;
}

.message-content .inline-code {
  white-space: pre-wrap;
  word-break: break-word;
  max-width: 100%;
  overflow-wrap: break-word;
  display: inline-block;
}

.bot-tips {
  max-width: 100%;
}

.message-content {
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
}