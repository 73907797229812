.app-chatbot-container {
  margin: 40px 0;
  display: flex;
  justify-content: center;
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 1299;
  box-shadow: 5px 5px 13px rgba(91, 81, 81, 0.4);
  font-family: 'Roboto', sans-serif;
  border-radius: 5px;
}

.react-chatbot-kit-chat-container {
  width: 340px;
}

.app-chatbot-button {
  width: 60px;
  height: 60px;
  border-radius: 25px;
  background-color: transparent;
  border: none;
  position: fixed;
  bottom: 15px;
  z-index: 1299;
  right: 30px;
}

.app-chatbot-button-icon {
  width: 60px;
  height: 60px;
  fill: #fff;
}

.react-chatbot-kit-chat-bot-message {
  background-color: #878fac;
  width: 100%;
  margin-left: 0;
}

.react-chatbot-kit-chat-bot-avatar {
  display: none;
}

.react-chatbot-kit-user-chat-message {
  inline-size: auto;
  overflow-wrap: break-word;
  display: flex;
  justify-content: flex-end;
}

.react-chatbot-kit-user-avatar {
  display: none;
}